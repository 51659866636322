<template>
  <f-wrapper page="login">
    <f-login-container>
      <h1>{{ $tt.capitalize($t('labels.login')) }}</h1>
    </f-login-container>

    <f-card>
      <f-login-form />

      <f-links />
    </f-card>
  </f-wrapper>
</template>

<script>
import Wrapper from '../../components/views/login/Wrapper.vue';
import FCard from '../../components/shared/Card.vue';
import FLoginContainer from '../../components/views/login/LoginContainer.vue';
import FLinks from '../../components/views/login/Links.vue';
import FLoginForm from '../../components/views/login/LoginForm.vue';

export default {
  components: {
    'f-wrapper': Wrapper,
    FCard,
    FLoginContainer,
    FLinks,
    FLoginForm,
  },
  mounted() {
    let loggedUser = localStorage.getItem('fluke-lms-user');
    if (loggedUser) {
      this.$router.push({
        name: 'course-list',
      });
    }
  },
};
</script>
