var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-links"},[_c('router-link',{staticClass:"muted",attrs:{"to":"/recovery"},on:{"click":function($event){return _vm.sendGtmData({
        category: 'login',
        event: 'click',
        action: 'login-click',
        label: 'btn-recovery',
      })}}},[_vm._v(_vm._s(_vm.$t('labels.recovery')))]),_c('span',{staticClass:"login-links__links__separator"},[_vm._v("|")]),_c('router-link',{staticClass:"muted",attrs:{"to":"/register"},on:{"click":function($event){return _vm.sendGtmData({
        category: 'login',
        event: 'click',
        action: 'login-click',
        label: 'btn-create-account',
      })}}},[_vm._v(_vm._s(_vm.$t('labels.createAccount')))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }