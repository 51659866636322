<template>
  <div>
    <div v-if="error" class="err" :class="{ active: error }">
      {{ $t('alerts.login.errorPassword') }}
    </div>
    <div v-if="message" class="info" :class="{ active: true }">
      {{ message }}
    </div>
    <v-text-field
      outlined
      id="input-email"
      :label="$tt.capitalize($t('labels.email'))"
      placeholder=" "
      v-model="auth.email"
      @keydown.enter="focusPassword"
    />
    <v-text-field
      id="input-password"
      v-model="auth.password"
      outlined
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :label="$tt.capitalize($t('labels.password'))"
      :type="showPassword ? 'text' : 'password'"
      ref="passwordInput"
      placeholder=" "
      @click:append="showPassword = !showPassword"
      @keydown.enter="login"
    ></v-text-field>
    <v-btn
      id="btn-login"
      large
      depressed
      block
      color="primary"
      @click="login"
      :disabled="sending"
      :loading="sending"
    >
      {{ $t('labels.login') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    auth: {
      email: '',
      password: '',
    },
    error: false,
    message: false,
    showPassword: false,
  }),
  methods: {
    async login() {
      let loginGtmData = {};
      this.error = false;
      this.message = false;
      const result = await this.$store.dispatch('auth/login', {
        email: this.auth.email,
        password: this.auth.password,
      });

      this.$gtm.trackEvent({
        category: 'login',
        event: 'click',
        action: 'login-click',
        label: 'btn-login',
      });

      if (result) {
        loginGtmData = {
          category: 'login',
          event: 'response',
          action: 'login-response',
          label: 'login-response-success',
          value: 1,
        };

        this.$i18n.locale = result.lang;
        this.$vuetify.lang.current = result.lang;
        if (result.create_password) {
          this.message = this.$t('labels.welcomeCreatePass');
        } else if (!result.accept) {
          this.$store.state.system.isLocked = true;
          this.$router.push({
            name: 'profile-privacy',
          });
        } else if (!result.flukeAcademyID || !result.country) {
          this.$router.push({
            name: 'profile-data',
          });
        } else {
          this.$router.push({
            name: 'course-list',
          });
        }
      } else {
        loginGtmData = {
          category: 'login',
          event: 'response',
          action: 'login-response',
          label: 'login-response-fail',
          value: 0,
        };
        this.error = true;
      }

      this.$gtm.trackEvent(loginGtmData);
    },
    focusPassword() {
      this.$refs.passwordInput.focus();
    },
  },
  computed: {
    sending() {
      const sending = this.$store.getters['auth/getSending'];
      return sending;
    },
  },
};
</script>
