<template>
  <div class="login-links">
    <router-link
      class="muted"
      to="/recovery"
      @click="
        sendGtmData({
          category: 'login',
          event: 'click',
          action: 'login-click',
          label: 'btn-recovery',
        })
      "
      >{{ $t('labels.recovery') }}</router-link
    >
    <span class="login-links__links__separator">|</span>
    <router-link
      class="muted"
      to="/register"
      @click="
        sendGtmData({
          category: 'login',
          event: 'click',
          action: 'login-click',
          label: 'btn-create-account',
        })
      "
      >{{ $t('labels.createAccount') }}</router-link
    >
  </div>
</template>

<script>
export default {
  methods: {
    sendGtmData(data) {
      this.$gtm.trackEvent(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-links {
  text-align: center;
  padding-top: 30px;

  .login-links__links__separator {
    display: inline-block;
    padding: 0 12px;
  }
}
</style>
